import React, { useEffect } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Anchor from '../components/Anchor'
import PagePadding from '../components/PagePadding'
import Paragraph from '../components/Paragraph'
import Lead from '../components/Lead'
import PageData from '../types/PageData'

type Props = {
  data: {
    sanityPage: PageData
  }
}

const WorksheetsPage: React.FC<Props> = ({ data }) => {
  useEffect(() => {
    function ShopifyBuyInit() {
      var client = ShopifyBuy.buildClient({
        domain: 'cody-calligraphy.myshopify.com',
        storefrontAccessToken: 'a0bd6b8ca631575b7162630fe915fd79',
      })
      ShopifyBuy.UI.onReady(client).then(function(ui) {
        ui.createComponent('collection', {
          id: '161398489142',
          node: document.getElementById('collection-component-1579896080484'),
          moneyFormat: '%24%7B%7Bamount%7D%7D',
          options: {
            product: {
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': 'calc(25% - 20px)',
                    'margin-left': '20px',
                    'margin-bottom': '50px',
                    width: 'calc(25% - 20px)',
                  },
                  img: {
                    height: 'calc(100% - 15px)',
                    position: 'absolute',
                    left: '0',
                    right: '0',
                    top: '0',
                  },
                  imgWrapper: {
                    'padding-top': 'calc(75% + 15px)',
                    position: 'relative',
                    height: '0',
                  },
                },
                title: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'normal',
                  color: '#2a2a2a',
                },
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                price: {
                  'font-family': 'Montserrat, sans-serif',
                  color: '#2a2a2a',
                },
                compareAt: {
                  'font-family': 'Montserrat, sans-serif',
                  color: '#2a2a2a',
                },
                unitPrice: {
                  'font-family': 'Montserrat, sans-serif',
                  color: '#2a2a2a',
                },
                description: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-size': '16px',
                  color: '#2a2a2a',
                },
              },
              buttonDestination: 'modal',
              contents: {
                options: false,
              },
              text: {
                button: 'View Details',
              },
              googleFonts: ['Montserrat'],
            },
            productSet: {
              styles: {
                products: {
                  '@media (min-width: 601px)': {
                    'margin-left': '-20px',
                  },
                },
              },
            },
            modalProduct: {
              contents: {
                img: false,
                imgWithCarousel: true,
              },
              styles: {
                product: {
                  '@media (min-width: 601px)': {
                    'max-width': '100%',
                    'margin-left': '0px',
                    'margin-bottom': '0px',
                  },
                },
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                title: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'normal',
                },
                price: {
                  'font-family': 'Raleway, sans-serif',
                },
                compareAt: {
                  'font-family': 'Raleway, sans-serif',
                },
                unitPrice: {
                  'font-family': 'Raleway, sans-serif',
                },
              },
              googleFonts: ['Montserrat', 'Raleway'],
              text: {
                button: 'Add to Cart',
              },
            },
            cart: {
              styles: {
                button: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  'background-color': '#994837',
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                  'border-radius': '15px',
                },
                title: {
                  color: '#2a2a2a',
                },
                header: {
                  color: '#2a2a2a',
                },
                lineItems: {
                  color: '#2a2a2a',
                },
                subtotalText: {
                  color: '#2a2a2a',
                },
                subtotal: {
                  color: '#2a2a2a',
                },
                notice: {
                  color: '#2a2a2a',
                },
                currency: {
                  color: '#2a2a2a',
                },
                close: {
                  color: '#2a2a2a',
                  ':hover': {
                    color: '#2a2a2a',
                  },
                },
                empty: {
                  color: '#2a2a2a',
                },
                noteDescription: {
                  color: '#2a2a2a',
                },
                discountText: {
                  color: '#2a2a2a',
                },
                discountIcon: {
                  fill: '#2a2a2a',
                },
                discountAmount: {
                  color: '#2a2a2a',
                },
              },
              text: {
                total: 'Total',
                notice: 'You will receive your PDF download after purchasing.',
                button: 'Checkout',
              },
              popup: false,
              googleFonts: ['Montserrat'],
            },
            toggle: {
              styles: {
                toggle: {
                  'font-family': 'Montserrat, sans-serif',
                  'font-weight': 'bold',
                  'background-color': '#994837',
                  ':hover': {
                    'background-color': '#8a4132',
                  },
                  ':focus': {
                    'background-color': '#8a4132',
                  },
                },
              },
              googleFonts: ['Montserrat'],
            },
            lineItem: {
              styles: {
                variantTitle: {
                  color: '#2a2a2a',
                },
                title: {
                  color: '#2a2a2a',
                },
                price: {
                  color: '#2a2a2a',
                },
                fullPrice: {
                  color: '#2a2a2a',
                },
                discount: {
                  color: '#2a2a2a',
                },
                discountIcon: {
                  fill: '#2a2a2a',
                },
                quantity: {
                  color: '#2a2a2a',
                },
                quantityIncrement: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
                quantityDecrement: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
                quantityInput: {
                  color: '#2a2a2a',
                  'border-color': '#2a2a2a',
                },
              },
            },
          },
        })
      })
    }
    ShopifyBuyInit()
  }, [])

  return (
    <Layout pageData={data?.sanityPage} pageTitle="Calligraphy Worksheets">
      <Seo
        title="Online Calligraphy Worksheets"
        description="Learn modern calligraphy at your own pace with downloadable workbooks and the Modern Calligraphy Starter Kit."
      />
      <Container>
        <Blurb>
          <Lead>
            If you can&rsquo;t attend one of my{' '}
            <Anchor as={Link} to="/workshops">
              in-person workshops
            </Anchor>
            , these downloadable, print-at-home workbooks are the next best
            thing!
          </Lead>
          <Paragraph>
            Recommended for use with a{' '}
            <Anchor as={Link} to="/blog/my-favourite-tools">
              small-tipped brush pen
            </Anchor>
            .
          </Paragraph>
        </Blurb>
        <section id="collection-component-1579896080484"></section>
      </Container>
    </Layout>
  )
}

export default WorksheetsPage

export const query = graphql`
  query WorksheetsPageQuery {
    sanityPage(uuid: { eq: "worksheets" }) {
      _rawPageMessage
    }
  }
`

const Container = styled(PagePadding)`
  padding-top: 50px;
  padding-bottom: 50px;
  min-height: 400px;

  @media (min-width: 601px) {
    /* Shopify items have 50px bottom margin */
    padding-bottom: 0;
  }
`

const Blurb = styled.section`
  max-width: 800px;
  margin: 0 auto 50px;
  text-align: center;
`
